import React from "react"
import RpmBenefitsCard from "../../components/serviceCard/rpmBenefitsCard"
import {
  ServContainer,
  ServHeader,
  ServWrapper,
} from "../../components/serviceSection/servElements"
import { BenefitHeader } from "../../components/providerSection/providerElements"

export default function RpmBenefits() {
  return (
    <ServContainer>
      <BenefitHeader>
        <ServHeader>Benefits</ServHeader>
      </BenefitHeader>
      <ServWrapper>
        <RpmBenefitsCard />
      </ServWrapper>
    </ServContainer>
  )
}
