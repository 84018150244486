import React from "react"
import Seo from "../components/seo"
import BannerSection from "../sections/rmt-section/banner"
import WorkFlowSection from "../sections/rmt-section/workflow"
import BenefitsSection from "../sections/rmt-section/RpmBenefits"
import RpmWhyNowSection from "../sections/rmt-section/RpmWhyNow"
import RpmWhyUsSection from "../sections/rmt-section/RpmWhyUs"
import Faq from "../sections/faq/RmtFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"

export default function service2() {
  return (
    <>
    <Seo
        title="Remote Patient Monitoring | Afyabook"
        description="Have peace of mind when it comes to your health. Allows you and your healthcare provider to manage your health from the comfort of your own home"
        img="https://www.afyabook.com/static/bcbc5b3e1123cc303539aba298f1482f/66dad/banner-thumb.webp"
        keywords="remote patient monitoring kenya, RPM"
        siteUrl="https://www.afyabook.com/remote-patient-monitoring"
        canonical="https://www.afyabook.com/remote-patient-monitoring"
      ></Seo>
      <BannerSection />
      <WorkFlowSection />
      <BenefitsSection />
      <RpmWhyNowSection />
      <RpmWhyUsSection />
      <Faq />
      <BotNav />
      <Footer />
    </>
  )
}
