import React from "react"
import styled from "styled-components"
import { desktop, mobileL, mobileM, mobileS, tablet } from "../../responsive"
import { CgInsights } from "react-icons/cg"
import { RiHospitalLine } from "react-icons/ri"
import { AiOutlineFall, AiOutlineRise } from "react-icons/ai"
const RpmBenefits = [
  {
    id: 1,
    icon: <CgInsights />,
    Desc: "Data driven treatment adjustment to improve care outcomes",
  },
  {
    id: 2,
    icon: <AiOutlineFall />,
    Desc: "Reduce readmissions rate and cases of complications",
  },
  {
    id: 3,
    icon: <RiHospitalLine />,
    Desc: "Reduce emergency room visits",
  },
  {
    id: 4,
    icon: <AiOutlineRise />,
    Desc: "Improved patient satisfaction",
  },
  {
    id: 5,
    icon: <AiOutlineFall />,
    Desc: "Cut costs of care",
  },
]
const ServCardContainer = styled.div`
  width: 250px;
  height: 250px;

  margin: 20px;
  background: #fefefe;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 38px 85px;
  cursor: pointer;
  &:hover {
    transition: all 0.4s ease-in-out;
    box-shadow: 0 3px 7px #a3d4cb;
    background: #fff;
    border: 1px;

    color: #205072;
  }
  ${mobileS({
    width: "70%",
    maxHeight: "250px",
    margin: "20px",
  })}
  ${mobileM({
    width: "70%",
    maxHeight: "250px",
    margin: "20px",
  })}
    ${tablet({ width: "40%" })}
    ${desktop({ width: "300px", height: "300px", margin: "25px" })}
`
const ServCardWrapper = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${mobileS({
    padding: "30px",
    paddingLeft: "35px",
  })}
  ${mobileM({
    width: "14em",
  })}
  ${mobileL({
    width: "17em",
  })}
  ${desktop({ padding: "10px" })}
`
const IconWrap = styled.span`
  svg {
    width: 60px;
    height: 60px;
    color: #1d9682;
    /* margin: 10px; */
  }
`
const Label = styled.div`
  width: 121px;
  display: none;
  color: black;
  background: rgba(199, 244, 196, 0.49);
  border-radius: 5px;
  margin: 23px;
  text-align: center;
  ${mobileS({
    width: "74px",
    fontSize: "10px",
    margin: "10px",
  })}
  ${mobileM({
    width: "74px",
    fontSize: "10px",
    margin: "10px",
  })}
`
const Img = styled.img`
  width: 20%;
  height: 30%;
  ${mobileS({ width: "2.8em", height: "50px", marginBottom: "15px" })}
  ${mobileM({ width: "2.8em", height: "50px", marginBottom: "15px" })}
  ${desktop({ width: "3em", height: "65px", marginBottom: "5px" })}
`
const Title = styled.p`
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
  ${mobileS({ fontSize: "1.3em", marginBottom: "5px" })}
  ${mobileM({ fontSize: "1.3em", marginBottom: "10px" })}
  ${desktop({
    marginBottom: "5px",
    fontWeight: "400",
    lineHeight: "30px",
  })}
`
const Subtitle = styled.span`
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.2em;
  /* identical to box height */

  text-align: left;

  color: #7c7c7c;
  margin-top: 5px;
  ${mobileS({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${mobileM({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${mobileL({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${tablet({ fontSize: "1.4em", lineHeight: "1.1em", fontWeight: "300" })}
  ${desktop({
    fontSize: "1.4em",
    lineHeight: "1.1em",
    fontWeight: "300",
    color: "#7c7c7c",
  })}
`
const Line = styled.hr`
  height: 1.5px;
  width: 80%;
  background-color: #1d9682;
  margin: 15px;
`
export default function ProvBenefitsCard() {
  return (
    <>
      {RpmBenefits.map(item => {
        const { id, icon, Desc } = item
        return (
          <>
            <ServCardContainer key={id}>
              <ServCardWrapper>
                <IconWrap>{icon}</IconWrap>
                <Line />
                <Title>{Desc}</Title>
              </ServCardWrapper>
            </ServCardContainer>
          </>
        )
      })}
    </>
  )
}
