import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "How do I join Afyabook for Remote patient monitoring?",
    body: "Send us an email to support@patameds.com and our representative will get in touch with on boarding details ",
  },
  {
    id: 1,
    title: "Do I pay anything to be on Afyabook?",
    body: "No, it’s free to have an account on Afyabook with free tools to manage your healthcare, though you might be required to subscribe to enjoy premium benefits like exclusive discounts, patient monitoring, health data analytics and advanced features. ",
  },
  {
    id: 3,
    title: "What is Remote Patient Monitoring?",
    body: "Remote patient monitoring (RPM) is a healthcare delivery method that uses technology to monitor patient health outside of a traditional clinical setting and electronically transmit information between patients and healthcare providers",
  },
  {
    id: 4,
    title: "What are other benefits of Remote Patient Monitoring?",
    body: "Improve medication compliance. Shorter length of stay in hospitals. Hospital at home intervention. Improved chronic disease management and post-surgical recovery",
  },
  {
    id: 5,
    title: "Who does the remote patient monitoring?",
    body: "Reviews can only be carried out by appropriate doctors, clinical pharmacists, pharmacists and other healthcare practitioners who possess the competencies required to carry out effective patient monitoring.",
  },
  {
    id: 6,
    title: "Preparing for your remote patient monitoring",
    body: "Employ the use of various types of devices, like weight scales, pulse oximeters, blood glucose meters, blood pressure monitors, heart monitors, wearables like smartwatches to continuous blood glucose monitors.Monitoring conducted via questionnaires: Every morning patients answer multiple questions and enter different medical parameters (like body temperature or blood pressure) on their own.",
  },
  {
    id: 7,
    title: "How to use remote patient monitoring with telehealth",
    body: "Remote patient monitoring pairs well with telehealth when patients need to be monitored for certain health conditions. It can also prevent health complications in patients who aren’t able to easily travel.",
  },
  {
    id: 8,
    title:
      "What symptoms and conditions are tracked through remote patient monitoring?",
    body: "High blood pressure, Diabetes, Weight loss or gain, Heart conditions, Chronic obstructive pulmonary disease, Sleep apnea, Asthma",
  },
  {
    id: 9,
    title:
      "Other conditions that may require complicated devices that will require patient training:",
    body: "Apnea monitors, Heart monitors, Specialized monitors for dementia and Parkinson’s disease, Breathing apparatuses, Fetal monitors",
  },
  {
    id: 10,
    title: "Which patients can really benefit from remote patient monitoring?",
    body: "Patients with chronic diseases, post-operative patients, senior patients, and patients from rural areas with limited access to healthcare facilities.",
  },
  {
    id: 11,
    title:
      "How do I get more information about the Remote Patient Monitoring program?",
    body: "If you would like additional information, contact our team at support@patameds.com or +254 792 640 973",
  },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions</h1>
          <h3 className="subheading">Some frequent questions clients ask</h3>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
