import React from 'react';

import {
  CardWrapper,
  Desc,
  Heading,
  IconBox,
  WorkflowCard,
  WorkflowInner,
  WorkflowSection,
  FlowHeaderRpm,
  FlowHeadingRpm,
  InnerContainer,
} from './styles/rpmWorkflowElements';

const workflowData = [
  {
    title: 'What is Remote Patient Monitoring',
    text: 'The set of technologies and practices enabling healthcare providers to track real-time changes in a patient’s health data from a distance and use it in a treatment plan',
  },
  {
    title: "Let's look at an example",
    text: 'For example, cardiac patients use portable devices or questionnaires to supply their care team with biometric data such as heart rate, blood pressure, and blood oxygen levels',
  },
  {
    title: 'Its value',
    text: 'The team can track this data to ensure the patient is recovering as expected or detect problems early and prevent complications',
  },
];

function WorkFlow() {
  return (
    <WorkflowSection>
      <InnerContainer>
        <FlowHeaderRpm>
          <FlowHeadingRpm>Let's see how it works</FlowHeadingRpm>
        </FlowHeaderRpm>

        <WorkflowInner>
          {workflowData.map((item, index) => (
            <WorkflowCard key={index}>
              <IconBox>{`0${index + 1}`}</IconBox>
              <CardWrapper>
                <Heading>{item.title}</Heading>
                <Desc>{item.text}</Desc>
              </CardWrapper>
            </WorkflowCard>
          ))}
        </WorkflowInner>
      </InnerContainer>
    </WorkflowSection>
  );
}

export default WorkFlow;
