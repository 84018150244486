import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { StaticImage } from 'gatsby-plugin-image';
import {
  BannerBtn,
  BannerContainer,
  BannerWrapper,
  Heading,
  ImgCont2,
  ScrollerContainer,
  ScrollText,
  ScrollWheel,
  Subtitle,
  TextContainer,
} from '../service-section/styles/bannerElements';
import {
  HeaderWrapper,
  ModalHeader,
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
  SuccessContainer,
  SuccessHeader,
  SuccessImg,
  SuccessSubtitle,
  SuccessText,
} from '../../components/providerSection/providerElements';
import HeroSuccess from '../service-section/images/thankyou.svg';
import useAuthState from '../../stores/auth';

export default function Banner() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSuccess('');
    setError('');
  };
  const handleShow = () => setShow(true);

  const { user } = useAuthState();

  const [name, setName] = useState(
    user?.firstName
      ? user.firstName
      : '' + `${user?.lastName}`
        ? user.lastName
        : '',
  );

  const [email, setEmail] = useState(user?.email || '');

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const uploadData = async () => {
    try {
      setError('');
      setSuccess('');
      if (!name || !email) {
        setError('Missing required fields');
      } else {
        setLoading(true);

        const data = {
          name,
          email,
          type: 'patientRmt',
          // change type for each page
        };
        await axios.post(`${process.env.GATSBY_API_URL}/registrations/`, {
          ...data,
        });
        // uncomment later on

        setSuccess('Success');
        setLoading(false);
        setSubmitted(true);
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error',
        err,
      );
      setLoading(false);
      const { message } = err.response.data;
      setError(message);
    }
  };

  return (
    <>
      <BannerContainer>
        <BannerWrapper>
          <TextContainer>
            <Heading>Remote Patient Monitoring</Heading>
            <Subtitle>
              Have peace of mind when it comes to your health
              <br />
              <br />
              Allows you and your healthcare provider to manage your health from
              the comfort of your own home
            </Subtitle>
            <BannerBtn onClick={handleShow}>Get started</BannerBtn>
            <Modal
              show={show}
              onHide={handleShow}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StyledForm>
                {success && (
                  <SuccessContainer>
                    <StyledIcon onClick={handleClose} />
                    <SuccessImg src={HeroSuccess} />
                    <SuccessHeader>
                      Welcome onboard,
                      {name}
                    </SuccessHeader>
                    <SuccessText>Thank you for joining</SuccessText>
                    <SuccessSubtitle>
                      Remote Patient Monitoring
                    </SuccessSubtitle>
                  </SuccessContainer>
                )}
                {!success && (
                  <>
                    <StyledIcon onClick={handleClose} />
                    <HeaderWrapper>
                      {/* <ModalImg src={BannerBg} /> */}
                      <ModalHeader>
                        Join Afyabook Remote Patient Monitoring program
                      </ModalHeader>
                      <Subtitle>
                        Have peace of mind when it comes to your health.
                        {' '}
                        <br />
                        Allows you and your healthcare provider to manage your
                        health from the comfort of your own home.
                      </Subtitle>
                    </HeaderWrapper>
                    <label htmlFor="name">Name</label>
                    <StyledInput
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <label htmlFor="email">Email</label>
                    <StyledInput
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {loading && (
                      <p style={{ color: 'green', fontSize: '1.2rem' }}>
                        Loading...
                      </p>
                    )}
                    <StyledButton
                      disabled={submitted || loading || !name || !email}
                      onClick={() => uploadData()}
                    >
                      Send Message
                    </StyledButton>
                    {' '}
                  </>
                )}
              </StyledForm>
            </Modal>
          </TextContainer>
          <ImgCont2>
            <StaticImage
              src="../../images/serviceimg/banner-thumb.png"
              alt="Remote Patient Monitoring"
              placeholder="blurred"
            />
          </ImgCont2>
        </BannerWrapper>
        <ScrollerContainer>
          <ScrollWheel />
        </ScrollerContainer>
      </BannerContainer>
      <ScrollText>Scroll down to learn more</ScrollText>
    </>
  );
}
